<template>
  <div class="box w-full mb-4 b-t-c">
    <div class="p-2">
      <div class="flex justify-between items-center">
        <span class="text-sm font-bold capitalize p-2">
          {{ descriptionStage }}
        </span>

        <div class="flex px-2 items-center rounded-md">
          <Button
            icon="pi pi-file-pdf"
            class="p-button-rounded p-button-plain p-mr-2 p-button-danger"
            v-tooltip.top="'Soportes de la fase'"
            @click="openModalSupports(numberStage)"
          />

          <Button
            :icon="[isVisible ? 'pi pi-minus' : 'pi pi-plus']"
            class="p-button-rounded p-button-xs"
            :class="[isVisible ? 'p-button-warning' : 'p-button-success']"
            @click.prevent="stageDetails(numberStage, isVisible)"
          />
        </div>
      </div>

      <div class="flex flex-col items-center dark:bg-gray-900">
        <div class="grid bg-white dark:bg-dark-1 dark:text-gray-500 w-full sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3">
          <div class="flex flex-col items-center bg-light-primary rounded border-primary border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Valor Presupuesto</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(subtotalStage, 1) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-warning rounded border-warning border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Ejecución de Obra</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(executionValueStage, 1) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-danger rounded border-danger border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Saldo</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700">{{ balance }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isVisible"
      class="flex flex-col p-4 intro-y">
      <table-stage-details :number-stage="numberStage" :project="project" />
    </div>

<!--    <div class="p-4 mt-4">
      <div class="grid gap-2 bg-white dark:bg-dark-1 dark:text-gray-500 w-full sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3">
        <item-card :image="image1"/>
        <item-card :image="image2"/>
        <item-card :image="image3"/>
      </div>
    </div>-->

    <Dialog :header="`Soportes - Fase ${numberStage}: ${descriptionStage}`" v-model:visible="displayModalSupports" :breakpoints="{'960px': '95vw'}" :style="{width: '60vw'}" :modal="true">
      <div class="grid gap-4 bg-white dark:bg-dark-1 dark:text-gray-500 w-full sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 xxl:grid-cols-5">
        <div class="sm:col-span-2 lg:col-span-5 xl:col-span-5 xxl:col-span-5">
          <p class="mb-4">
            Información de los soportes
          </p>
          <DataTable
            :value="supports"
            responsiveLayout="stack"
            breakpoint="960px"
            class="p-datatable-sm"
            showGridlines
          >
            <Column field="id" header="ID"></Column>
            <Column field="name" header="Nombre">
              <template #body="slotProps">
                <span>Soporte {{ slotProps.data.id }}</span>
              </template>
            </Column>
            <Column field="description" header="Comentario">
              <template #body="{ data }">
                <span>{{ [undefined, null, 'null', "0", 0].includes(data.description) ? '' : data.description }}</span>
              </template>
            </Column>
            <Column field="createdAt" header="Fecha">
              <template #body="{ data }">
                <span>{{ data.createdAt.substring(0, 10) }}</span>
              </template>
            </Column>
            <Column field="" header="Acciones">
              <template #body="slotProps">
                <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-xs p-button-default p-mr-2" @click="onShowSupport(slotProps.data.adjunto)" v-tooltip.top="'Ver o descargar el soporte'"/>
                <Button icon="pi pi-trash" class="p-button-rounded p-button-xs p-button-danger" @click="onDeleteSupport(slotProps.data.id)" v-tooltip.top="'Eliminar el soporte'"/>
              </template>
            </Column>
          </DataTable>
        </div>
        <div class="sm:col-span-2 lg:col-span-5 xl:col-span-5 xxl:col-span-5">
          <p class="mb-4">
            Cargue del soporte:
          </p>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12">
              <FilePond ref="pondFile" :number-stage="numberStage" :description="description" :url="`${urlSave}/soportes`"/>
            </div>
            <div class="p-field p-col-12 p-md-12">
              <label for="name">Comentario:</label>
              <Textarea
                v-model="description"
                id="description"
                :autoResize="true"
                rows="2"
              />
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <Button label="Cerrar" icon="pi pi-times" @click="closeModalSupports" class="p-button-xs p-button-danger"/>
        <Button label="Guardar" icon="pi pi-check" @click="submitSupport(numberStage)" class="p-button-xs p-button-success" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script>

import { computed, defineAsyncComponent, ref } from 'vue'
import listSupportsByStage from '../../../../../services/findSupportsByStageId'
import deleteSupport from '../../../../../services/deleteSupport'
import { useToast } from 'primevue/usetoast'
import { error, messageWarningDelete, warning } from '../../../../../../../../libs/mensajes'
import { useStore } from 'vuex'
import currency from 'currency.js'

export default {
  name: 'stageSupportItem',
  components: {
    // itemCard: defineAsyncComponent(() => import('../../../../../../accounting/amortizations/views/dashboard/card'))
    FilePond: defineAsyncComponent(() => import('../uploadFilePond')),
    TableStageDetails: defineAsyncComponent(() => import('../stageDetails'))
  },
  props: {
    numberStage: {
      type: Number,
      default: null
    },
    project: {
      type: String,
      default: null
    },
    descriptionStage: {
      type: String,
      default: ''
    },
    subtotalStage: {
      type: Number,
      default: 0
    },
    executionValueStage: {
      type: Number,
      default: 0
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {
    const description = ref('')
    const store = useStore()
    const urlSave = ref(process.env.VUE_APP_MICROSERVICE_API_CAPITAL_STATE_CONSTRUCTION)
    const toast = useToast()
    const pondFile = ref(null)
    const supports = ref([])
    const displayModalSupports = ref(false)
    const image1 = ref('https://images.unsplash.com/photo-1606398496208-32d1d0f9b14b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80')
    const image2 = ref('https://images.unsplash.com/photo-1589939705384-5185137a7f0f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80')
    const image3 = ref('https://images.unsplash.com/photo-1508450859948-4e04fabaa4ea?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=779&q=80')

    /* Obtener la información de los soportes por ID de la fase */
    const fetchSupportsByStage = async (numberStage) => {
      listSupportsByStage({ id: numberStage }).then(({ status, data: response }) => {
        console.log('Response: ', response)
        if (status !== 200) {
          toast.add({ severity: 'error', summary: 'Error', detail: 'Problemas al listar los soportes de la fase, consulte con el administrador del sistema', life: 3000 })
        } else {
          supports.value = response
        }
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Abrir el modal */
    const openModalSupports = (numberStage) => {
      displayModalSupports.value = true
      description.value = null
      fetchSupportsByStage(numberStage)
    }

    /* Cerrar el modal */
    const closeModalSupports = () => {
      displayModalSupports.value = false
      description.value = null
    }

    /* Visualizar o descargar soportes */
    const onShowSupport = (adjunto) => {
      const authorization = `${store.getters['auth/getToken']}`
      const url = `${process.env.VUE_APP_MICROSERVICE_API_CAPITAL_STATE_CONSTRUCTION}/soportes/descargar${adjunto}?token=${authorization}`

      if (adjunto !== null) {
        window.open(url, '_blank')
      } else {
        warning('No hay soporte')
      }
    }

    /* Eliminar el archivo y dato del soporte */
    const onDeleteSupport = (supportId) => {
      messageWarningDelete('', 'Eliminar Soporte', 'warning', '¿Esta seguro de eliminar el soporte, esta acción es irreversible?').then(({ isConfirmed }) => {
        if (isConfirmed) {
          deleteSupport({ id: supportId }).then(() => {
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Soporte eliminado correctamente', life: 3000 })
            fetchSupportsByStage(props.numberStage)
          }).catch((err) => {
            console.error(err.message)
            error(err.response.data.message)
          })
        }
      })
    }

    /* Funcion para subir los archivos del componente filepond */
    const submitSupport = (numberStage) => {
      pondFile.value.onSubmitSupport().then(async () => {
        await fetchSupportsByStage(numberStage).then(async () => {
          await pondFile.value.onRemoveSupport()
          description.value = null
        })
      })
    }

    /**/
    const balance = computed(() => {
      return `$${currency(props.subtotalStage - props.executionValueStage, { separator: '.', precision: 1, decimal: ',', symbol: '' }).format()}`
    })

    const stageDetails = (numberStage, isVisible) => {
      context.emit('stageDetails', numberStage, isVisible)
    }

    return {
      displayModalSupports,
      openModalSupports,
      closeModalSupports,
      image1,
      image2,
      image3,
      supports,
      onDeleteSupport,
      onShowSupport,
      urlSave,
      submitSupport,
      pondFile,
      description,
      balance,
      stageDetails
    }
  }
}
</script>

<style lang="scss">

.border-dashed {
  border-style: dashed !important;
  border-color: #e4e6ef;
}
.bg-light-primary {
  background-color: #F1FAFF !important;
}
.bg-light-success {
  background-color: #e8fff3!important
}
.bg-light-info {
  background-color: #f8f5ff!important
}
.bg-light-warning {
  background-color: #fff8dd!important
}
.bg-light-danger {
  background-color: #fff5f8!important
}
.border-primary {
  border-color: #009ef7 !important;
}
.border-success {
  border-color: #50cd89!important
}

.border-info {
  border-color: #7239ea!important
}

.border-warning {
  border-color: #ffc700!important
}

.border-danger {
  border-color: #f1416c!important
}
.rounded {
  border-radius: .475rem !important;
}
.border-primary {
  border-color: #009ef7 !important;
}
.b-t-c {
  border-left-color: #00bcd4;
  border-left-width: 6px;
}
.swal2-container {
  z-index: 9999;
}
</style>
